export const Usericon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
    >
      <path
        d="M11.8535 10.375C10.834 10.375 10.377 10.9375 8.72461 10.9375C7.03711 10.9375 6.58008 10.375 5.56055 10.375C2.95898 10.375 0.849609 12.5195 0.849609 15.1211V16.5625C0.849609 17.5117 1.58789 18.25 2.53711 18.25H14.9121C15.8262 18.25 16.5996 17.5117 16.5996 16.5625V15.1211C16.5996 12.5195 14.4551 10.375 11.8535 10.375ZM15.4746 16.5625C15.4746 16.8789 15.1934 17.125 14.9121 17.125H2.53711C2.2207 17.125 1.97461 16.8789 1.97461 16.5625V15.1211C1.97461 13.1172 3.55664 11.5 5.56055 11.5C6.26367 11.5 6.93164 12.0625 8.72461 12.0625C10.4824 12.0625 11.1855 11.5 11.8535 11.5C13.8574 11.5 15.4746 13.1172 15.4746 15.1211V16.5625ZM8.72461 9.25C11.1855 9.25 13.2246 7.24609 13.2246 4.75C13.2246 2.28906 11.1855 0.25 8.72461 0.25C6.22852 0.25 4.22461 2.28906 4.22461 4.75C4.22461 7.24609 6.22852 9.25 8.72461 9.25ZM8.72461 1.375C10.5527 1.375 12.0996 2.92188 12.0996 4.75C12.0996 6.61328 10.5527 8.125 8.72461 8.125C6.86133 8.125 5.34961 6.61328 5.34961 4.75C5.34961 2.92188 6.86133 1.375 8.72461 1.375Z"
        fill="#C9C5C5"
      />
    </svg>
  );
};
