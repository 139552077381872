import "./ProductionChart.scss";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useSelector } from "react-redux";

const ProductionChart = () => {
  const roofInfo = useSelector((state) => state.Roof.roofInfo);
  let panelCount = useSelector((state) => state.Order.panelCount);
  let orderInfo = useSelector((state) => state.Order);
  let data;
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ];

  if (Object.keys(roofInfo).length > 0 && !roofInfo.message) {
    for (const key in roofInfo.paneler) {
      if (roofInfo.paneler[key].antallPaneler === +panelCount) {
        data = roofInfo.paneler[key].produksjon.filter(
          (item) => item.month !== "sum"
        );
      }
    }
  }

  for (const key in data) {
    data[key].month = months[key];
  }

  return (
    <LineChart
      width={390}
      height={240}
      data={data}
      margin={{
        top: 35,
        right: 30,
        bottom: 0,
        left: 0,
      }}
    >
      <CartesianGrid strokeDasharray="0" vertical={false} />
      <XAxis
        dataKey="month"
        interval={0}
        angle={-30}
        tickLine={false}
        fontSize={14}
      />
      <YAxis
        axisLine={false}
        tickLine={false}
        label={{ value: "kWh pr. mnd.", angle: -90, position: "insideLeft" }}
      />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="kWhsnittmnd"
        stroke="#da3b21"
        activeDot={{ r: 8 }}
        dot={false}
        name="Produksjon"
        strokeWidth={4}
      />
      {orderInfo.partnerId === 10005 ? (
        ""
      ) : (
        <Line
          type="monotone"
          dataKey="kWhm2snittmnd"
          stroke="#3669c9"
          dot={false}
          name="Solinnstraling/m&sup2;"
          strokeWidth={4}
        />
      )}
      <text
        x="180"
        y="0"
        dominantBaseline="hanging"
        fontSize="16"
        fontWeight="bold"
      >
        Estimert produksjon og solinnstråling
      </text>
    </LineChart>
  );
};

export default ProductionChart;
