import React from "react";
import Slider from "react-slick";
import "./SimpleSlider.scss";
import ModalPic1 from "../../assets/images/ModalPic1.jpg";
import ModalPic2 from "../../assets/images/ModalPic2.png";
import ModalPic3 from "../../assets/images/ModalPic3.png";
import ModalPic4 from "../../assets/images/ModalPic4.png";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";

const SimpleSlider = ({ handleClick }) => {
  const cookies = new Cookies();
  const [newCookie, setNewCookie] = useState("");

  const setAcceptCookies = () => {
    let uuid = uuidv4();
    cookies.set("access_token_accept", uuid, {
      path: "/",
      expires: new Date(Date.now() + 8.64e7),
    });
    setNewCookie(uuid);
  };

  const setSkipCookies = () => {
    let uuid = uuidv4();
    cookies.set("access_token_skip", uuid, {
      path: "/",
      expires: new Date(Date.now() + 8.64e7),
    });
    setNewCookie(uuid);
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  
  return (
    <Slider {...settings}>
      <div className="first-slider">
        <div>
          <img src={ModalPic1} alt="solkart.no" />
        </div>
        <div>
          <h1>Velkommen til Solkart</h1>
          <p>
            Denne nettsiden vil automatisk utforme planer for installasjon av
            solcellepaneler på hjemmetaket ditt og gi deg kostnadsoverslag i
            løpet av sekunder.
          </p>
        </div>
      </div>
      <div className="second-slider">
        <div>
          <img src={ModalPic2} alt="solkart.no" />
        </div>
        <div>
          <h1> Klikk på taket du vil montere</h1>
          <p>
            Søk på adressen din. Når du finner huset ditt på kartet, velg
            hvilken side av taket du vil installere solcellepanelene på. Jo mer
            rødlig fargen på taksiden er, jo sterkere energiproduksjon
          </p>
        </div>
      </div>
      <div className="second-slider">
        <div>
          <img src={ModalPic3} alt="solkart.no" />
        </div>
        <div>
          <h1> Velg taktype</h1>
          <p>
            Når du har klikket på taksiden din, må du velge taktype. Klikk på
            den for å gå videre til neste trinn.
          </p>
        </div>
      </div>
      <div className="second-slider">
        <div>
          <img src={ModalPic4} alt="solkart.no" className="fourth-pic" />
        </div>
        <div>
          <h1>Vi bruker informasjonskapsler</h1>
          <p>
            Vi benytter informasjonskapsler på{" "}
            <a
              href="https://www.solcellespesialisten.no/personvern"
              target="_blank"
            >
              solkart.no
            </a>{" "}
            for å kunne tilpasse tjenesten bedre. Les mer her.
          </p>
          <div>
            <button
              onClick={() => {
                setAcceptCookies();
                handleClick();
              }}
            >
              Aksepter
            </button>
            <button
              onClick={() => {
                setSkipCookies();
                handleClick();
              }}
            >
              Avvis
            </button>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default SimpleSlider;
