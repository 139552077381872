import React from "react";
import { Link, Outlet } from "react-router-dom";
import "./HeaderForIframe.scss";
import logoImage from "../../assets/images/solkartlogo.svg";

const HeaderForIframe = () => {

  return (
    <>
      <div className="header">
        <div className="container">
          <nav className="navigation">
            <div className="logo-image">
              <Link to="/">
                <img src={logoImage} alt="" />
              </Link>
            </div>
          </nav>
        </div>
        <Outlet />
      </div>
    </>
  );
};

export default HeaderForIframe;
