import Search from "../../components/Search/Search";
import "./Home.scss";
import homeImage from "../../assets/images/HomePage1.png";
import { motion } from "framer-motion";
import getMotionVariants from "../../api/getMotionVariants";

const Home = () => {
  return (
    // <motion.div
    //   variants={getMotionVariants()}
    //   initial="initial"
    //   animate="animate"
    //   transition={{ duration: 1, ease: "easeInOut" }}
    // >
      <div className="home-page-section">
        <div className="home-page-container">
          <Search />
          <div className="home-page-description">
            <h1 className="home-page-description-title">
              Med et solcelleanlegg på taket, kan du lage din egen strøm fra kun{" "}
              <span>
                25 øre/kWh* fast de neste 30 årene! Sjekk ditt tak nå!
              </span>
            </h1>
            <div className="home-page-description-section">
              <div className="home-page-description-text">
                <p>
                  <strong>
                    Endelig er solkart.no kommer til Norge. Solcellespesialisten
                    har basert på erfaringer fra våre kunder og data fra PVGIS
                    databasen kartlagt alle takene i hele Norge slik at du lett
                    kan se hvor mye solstrøm du kan lage på ditt eget tak. Du
                    vil også få et pristilbud på hva det vil koste å få et
                    solcelleanlegg på ditt tak. Enten om du vil installere det
                    selv eller om du vil benytte en av våre lokale
                    installatører.
                  </strong>
                </p>
                <p>
                  Solcellespesialisten har over 10 års erfaring med solceller i
                  Norge, og er det selskapet som har installert flest
                  nettilkoblede solcelleanlegg. Våre løsninger er tilpasset og
                  testet for Norske forhold og byggestandarder.
                  Solcellespesialisten har lager og hovedkontor i Fredrikstad,
                  samt et salgskontor i Trondheim. Vi installerer solcelleanlegg
                  i hele landet.
                </p>
                <h2>Hva viser vi på solkart.no?</h2>
                <ul>
                  <li>
                    <strong>
                      Hvilken takflate passer best til solcelleanlegg
                    </strong>
                    <br />
                    Basert på takvinkelen og retningen så har vi fargelagt alle
                    tak i Norge basert på hvor mye solenergi den aktuelle flaten
                    kan fange opp av sola på årsbasis. På fargeleggingen har vi
                    ikke tatt hensyn til lokale skyggeforhold.
                  </li>
                  <li>
                    <strong>
                      Hvor stort anlegg kan jeg få plass til og hva kan det
                      produsere pr år
                    </strong>
                    <br />
                    Når du klikker på et tak så gjør vi en analyse av hvor stort
                    solcelleanlegg som systemet tror du får plass til. Og basert
                    på dette så forespør systemet PVGIS databasen om hva
                    akkuratt ditt tak kan produsere. Her tar vi høyde for
                    skygger fra fjell, din takvinkel og takretning, og til slutt
                    så korrigerer vi produksjonen i forhold til de faktiske
                    målingene vi har gjort på andre hus i ditt område. Du får
                    også opp en forventet produksjonsgraf over året, samt en
                    oversikt over din horisont og hvordan sola beveger seg over
                    denne på sommer og vinter solverv.
                  </li>
                  <li>
                    <strong>
                      Hva tilsvarer kWh for strømmen et anlegg produserer over
                      sin levetid
                      <br />
                    </strong>
                    Da forventet tilbakebetalingstid tvinger oss til å gjøre en
                    antagelse over hvordan strøm og nettleie vil utvikle seg,
                    samt gjøre antagelser på hvor stort eget forbruk du har. Har
                    vi istedet valg å gi deg et sammenligningsgrunnlag der det
                    ikke er mye usikkerhet:
                    <br />
                    <strong>
                      (Hva koster solcelleanlegget deg i dag) / (Hvor mye det
                      vil produsere over levetiden) = Kostnad pr kWh over
                      levetiden.
                      <br />
                    </strong>
                    Siden solcelleanlegg reduserer seg med ca 0,5% i året, har
                    vi satt at snitt produksjonen over 30 år blir 92,75% av 1.
                    års produksjon. De fleste paneler leveres med 25 års
                    effektgaranti, men mange er også testet for levetider opp
                    mot 70 år. Så vi har satt at levetiden er den samme som
                    levetiden for et tak som er 30 år. Vi har ikke hensyntatt
                    renten på investert beløp.
                  </li>
                </ul>
                <p>
                  * Prisen pr kWh er regnet ved å ta prisen på et 3.1 kWp
                  solcelleanlegg i Fredrikstad etter Enovastøtte og delt dette
                  på forventet produksjon de neste 30 årene. Vi har antatt at
                  anlegget vil svekkes over 30 års perioden, slik at snitt
                  produksjon pr år vil være 92,5% av 1 års produksjon.
                </p>
              </div>
              <div className="home-page-description-image">
                <img
                  className="home-page-image"
                  src={homeImage}
                  alt="solcellespesialisten-team-installerer-solcelle.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    // </motion.div>
  );
};

export default Home;
