import OrderMap from "../../components/OrderMap/OrderMap";
import RoofColor from "../../components/RoofColor/RoofColor";
import RoofDescription from "../../components/RoofDescription/RoofDescription";
import Search from "../../components/Search/Search";
import "./SearchResults.scss";
import { motion } from "framer-motion";
import getMotionVariants from "../../api/getMotionVariants";

const SearchResults = () => {
  return (
    // <motion.div
    //   variants={getMotionVariants()}
    //   initial="initial"
    //   animate="animate"
    //   transition={{ duration: 1, ease: "easeInOut" }}
    // >
    <>
      <OrderMap />
      <div className="map-section">
        <Search />
        <RoofDescription />
        <RoofColor />
      </div>
    </>
    // </motion.div>
  );
};

export default SearchResults;
