import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AnimatePresence } from "framer-motion";
import Cookies from "universal-cookie";

import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import Solutions from "../pages/Solutions/Solutions";
import Contact from "../pages/Contact/Contact";
import SubmissionView from "../pages/SubmissionView/SubmissionView";
import SearchResults from "../pages/SearchResults/SearchResults";
import Offer from "../pages/Offer/Offer";
import Unsubscribe from "../pages/Unsubscribe/Unsubscribe";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import HeaderForIframe from "../components/HeaderForIframe/HeaderForIframe";
import PopupModal from "../components/PopupModal/PopupModal";
import Cookie from "../components/Cookie/Cookie";
import { setPartnerId } from "../store/Order/action";
import { getPartnerId } from "../api/getPartnerId";

const Router = () => {
  const cookies = new Cookies();
  const location = useLocation();
  const dispatch = useDispatch();
  const access_token_accept = cookies.get("access_token_accept");
  const access_token_reject = cookies.get("access_token_reject");
  const [newAcceptCookie, setNewAcceptCookie] = useState("");
  const [newRejectCookie, setNewRejectCookie] = useState("");
  const [currentPartner, setCurrentPartner] = useState("");

  useEffect(() => {
    const { pathname } = location;
    switch (pathname) {
      case "/":
        document.title =
          "Hjem | Finn solcelleanlegg til ditt tak på Solkart.no";
        break;
      case "/about":
        document.title =
          "Om Solkart.no | Finn solcelleanlegg til ditt tak på Solkart.no";
        break;
      case "/solutions":
        document.title =
          "Løsninger | Finn solcelleanlegg til ditt tak på Solkart.no";
        break;
      case "/contact":
        document.title =
          "Kontakt oss | Finn solcelleanlegg til ditt tak på Solkart.no";
        break;
      case "/offer":
        document.title =
          "Rekkefølge | Finn solcelleanlegg til ditt tak på Solkart.no";
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    setNewAcceptCookie(access_token_accept);
    setNewRejectCookie(access_token_reject);
  }, [access_token_accept, access_token_reject]);

  useEffect(() => {
    if (window.location.href === "https://solkart.no/?admin=1") {
      window.location.href = "http://classic.solkart.no/?admin=1";
    }

    if (window.location.href.indexOf("nolimits=1") > -1) {
      sessionStorage.setItem("noLimits", true);
    } else {
      let getNoLimits = sessionStorage.getItem("noLimits");
      if (getNoLimits === "true") {
        sessionStorage.removeItem("noLimits");
      }
    }

    let partnerId = getPartnerId(window.location.href);
    if (partnerId !== undefined) {
      dispatch(setPartnerId(partnerId));
      setCurrentPartner(partnerId);
    } else {
      setCurrentPartner(undefined);
    }
  }, []);

  return (
    <>
      {newAcceptCookie === undefined && newRejectCookie === undefined ? (
        <PopupModal />
      ) : (
        ""
      )}
      {currentPartner === undefined ? <Header /> : <HeaderForIframe />}
      <AnimatePresence wait initial={false}>
        <Routes location={location} key={location.key}>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/solutions" element={<Solutions />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/contact/view" element={<SubmissionView />}></Route>
          <Route path="/search" element={<SearchResults />}></Route>
          <Route path="/offer" element={<Offer />}></Route>
          <Route path="/unsubscribe/:id" element={<Unsubscribe />}></Route>
        </Routes>
      </AnimatePresence>
      <Footer />
      {newAcceptCookie === undefined && newRejectCookie === undefined ? (
        <Cookie />
      ) : (
        ""
      )}
    </>
  );
};

export default Router;
