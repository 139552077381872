import axios from "axios";
import { setOrder } from "../Order/action";
import { SET_ROOF_INFO, SET_ROOF_INTEGRATED, SET_ROOF_TYPES } from "./types";

export const setRoofTypes = (roofTypes) => {
  return {
    type: SET_ROOF_TYPES,
    roofTypes,
  };
};

export const getRoofTypes = () => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_OFFER_API_URL}/roofTypes`)
      .then((res) => {
        dispatch(setRoofTypes(res.data));
      });
  };
};

export const setRoofIntegrated = (roofIntegrated) => {
  return {
    type: SET_ROOF_INTEGRATED,
    roofIntegrated,
  };
};

export const setRoofInfo = (roofInfo) => {
  return {
    type: SET_ROOF_INFO,
    roofInfo,
  };
};

export const getRoofInfo =  (checkedAddress) => {
  const newObj = Object.fromEntries(
    Object.entries(checkedAddress).map(([k, v]) => [k.toLowerCase(), v])
  );

  let replacedGeometri = newObj.geometri.replace(
    String.fromCharCode(92),
    String.fromCharCode(92, 92)
  );
  let getNoLimits = sessionStorage.getItem("noLimits");
  let offerUrl;
  if (getNoLimits === "true") {
    offerUrl = `${process.env.REACT_APP_OFFER_API_URL}/offer/?nolimits=1`;
  } else {
    offerUrl = `${process.env.REACT_APP_OFFER_API_URL}/offer/`;
  }

  const offerObj = {
    lat: newObj.lat,
    lng: newObj.lng,
    helning: newObj.helning,
    retning: newObj.retning,
    lengde: newObj.lengde,
    bredde: newObj.bredde,
    areal2d: newObj.areal2d,
    areal3d: newObj.areal3d,
    eaveheight: newObj.eaveheight,
    typetak: newObj.typetak,
    produkttype: newObj.produkttype,
    partnerId: newObj.partnerid,
    kalkulertrinn: 1,
    voucherCode: newObj.vouchercode,
    geometri: JSON.parse(replacedGeometri),
  };

  return  (dispatch) => {
    axios
      .post(offerUrl, offerObj)
      .then((res) => {
        if (res.data.paneler.length > 0) {
          dispatch(setRoofInfo(res.data));
          let newData = { ...res.data };
          delete newData.paneler;
          dispatch(setOrder(newData));
        } else {
          dispatch(setRoofInfo({ message: "Error" }));
        }
      })
      .catch((error) => {
        dispatch(setRoofInfo(error));
      });
  };
};
