import "./CompleteOrder.scss";
import CompleteOrderPic from "../../assets/images/completeorder.png";
import { useSelector } from "react-redux";

const CompleteOrder = () => {
  let orderInfo = useSelector((state) => state.Order);

  return (
    <div className="complete-order-section">
      <div className="complete-order-container">
        {orderInfo.partnerId === 10005 ? (
          <h1>Takk for forespørselen!</h1>
        ) : (
          <h1>Takk for bestillingen!</h1>
        )}

        <p>
          Vi har registrert din forespørsel om tilbud. Du vil motta tilbud på
          epost så fort vi har gått igjennom forespørselen din.
        </p>
        <img src={CompleteOrderPic} alt="complete order" />
      </div>
    </div>
  );
};

export default CompleteOrder;
