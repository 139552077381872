import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getRoofCalculation } from "../../api/getRoofCalculation";
import ProductionChart from "../ProductionChart/ProductionChart";
import ProgressBar from "../ProgressBar/ProgressBar";
import "./RoofPanel.scss";

const RoofPanel = (data) => {
  const roofInfo = useSelector((state) => state.Roof.roofInfo);
  const panelCount = useSelector((state) => state.Order.panelCount);
  const [lcoe, setLcoe] = useState(0);
  let finalRoof;

  useEffect(() => {
    if (Object.keys(roofInfo).length > 0 && !roofInfo.message) {
      let found;
      if (panelCount === "") {
        found = roofInfo.paneler.find(
          (element) => element.pris * 1.25 >= 190000
        );
      } else {
        found = roofInfo.paneler.find(
          (element) => element.antallPaneler === panelCount
        );
      }
      let max;
      if (found !== undefined) {
        max = found.antallPaneler;
      } else {
        max = roofInfo.paneler[roofInfo.paneler.length - 1].antallPaneler;
      }

      if (roofInfo.paneler[roofInfo.paneler.length - 1].antallPaneler >= max) {
        finalRoof = roofInfo.paneler[max - 8];
      } else {
        finalRoof = roofInfo.paneler[roofInfo.paneler.length - 1];
      }
      let result = getRoofCalculation(finalRoof);
      setLcoe(result.lcoeC);
    }
  }, []);

  return (
    <>
      {Object.keys(data).length > 0 ? (
        <div className="roof-price-panel">
          <div className="panel-counts">
            <h3>Velg antall paneler</h3>
            <div className="panel-counts-scale">
              <span>{roofInfo.paneler[0].antallPaneler} paneler</span>
              <ProgressBar />
              <span>
                {roofInfo.paneler[roofInfo.paneler.length - 1].antallPaneler}{" "}
                paneler
              </span>
            </div>
          </div>
          <div className="panel-prices">
            <div>
              <p>Estimert årlig produksjon*</p>
              <p>
                {Intl.NumberFormat("en-US")
                  .format(Math.ceil(data.data.anProd))
                  .replace(",", " ")}{" "}
                kWh
              </p>
            </div>
            {data.data.lcoe < 0.8 ? (
              <div>
                <p>Strømpris</p>
                <p>
                  {data.data.lcoe > 0
                    ? data.data.lcoe.toFixed(3)
                    : lcoe.toFixed(3)}{" "}
                  kroner
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="panel-charts">
            <div className="panel-production-chart">
              <ProductionChart />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RoofPanel;
