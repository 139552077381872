export const Discounticon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path d="M24.625 16.1875L11.125 2.6875H2.6875V11.125L16.1875 24.625L24.625 16.1875Z" stroke="url(#paint0_linear_3725_15)" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.90625 8.59375C5.97427 8.59375 5.21875 7.83823 5.21875 6.90625C5.21875 5.97427 5.97427 5.21875 6.90625 5.21875C7.83823 5.21875 8.59375 5.97427 8.59375 6.90625C8.59375 7.83823 7.83823 8.59375 6.90625 8.59375Z" stroke="url(#paint1_linear_3725_15)" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.0625 6.0625L1 1" stroke="url(#paint2_linear_3725_15)" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.9688 8.59375L18.7188 15.3438" stroke="url(#paint3_linear_3725_15)" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.59375 11.9688L11.9688 15.3438" stroke="url(#paint4_linear_3725_15)" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
          <linearGradient id="paint0_linear_3725_15" x1="13.6562" y1="2.6875" x2="13.6562" y2="24.625" gradientUnits="userSpaceOnUse">
            <stop stopColor="#EF8439"/>
            <stop offset="1" stopColor="#E7463F"/>
          </linearGradient>
          <linearGradient id="paint1_linear_3725_15" x1="6.90625" y1="5.21875" x2="6.90625" y2="8.59375" gradientUnits="userSpaceOnUse">
            <stop stopColor="#EF8439"/>
            <stop offset="1" stopColor="#E7463F"/>
          </linearGradient>
          <linearGradient id="paint2_linear_3725_15" x1="3.53125" y1="1" x2="3.53125" y2="6.0625" gradientUnits="userSpaceOnUse">
            <stop stopColor="#EF8439"/>
            <stop offset="1" stopColor="#E7463F"/>
          </linearGradient>
          <linearGradient id="paint3_linear_3725_15" x1="15.3438" y1="8.59375" x2="15.3438" y2="15.3438" gradientUnits="userSpaceOnUse">
            <stop stopColor="#EF8439"/>
            <stop offset="1" stopColor="#E7463F"/>
          </linearGradient>
          <linearGradient id="paint4_linear_3725_15" x1="10.2812" y1="11.9688" x2="10.2812" y2="15.3438" gradientUnits="userSpaceOnUse">
            <stop stopColor="#EF8439"/>
            <stop offset="1" stopColor="#E7463F"/>
          </linearGradient>
        </defs>
      </svg>
    );
  };
  