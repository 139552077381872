import { config } from "./config";

export async function getMapCoordinates(latlngs) {
  const query = `https:${config.WAAPItakflaterPunktUrl}?X=${latlngs.lng}&Y=${latlngs.lat}&api_key=${config.apikey}&GeometryTextFormat=GeoJson`;

  const apiResult = await fetch(query, {
    headers: {
      Accept: "application/json",
    },
    method: "GET",
  });

  if (apiResult.status === 200) {
    return await getResponse(apiResult);
  }

  return null;
}

async function getResponse(apiResult) {
  const json = await apiResult.json();

  let teig = json[0];

  let eave;
  let eaveHeight;
  let newGeometri;

  if (teig === null) {
    return null;
  } else {
    let geometri = JSON.parse(teig.Geometri);
    let coordinates = geometri.coordinates[0];
    eave = getRoofEave(coordinates);
    newGeometri = getNewGeometri(coordinates);

    let res = await getElevation(eave);
    let masl = res[0].Z;
    let eaveMasl = eave[0].Z;
    eaveHeight = eaveMasl - masl;
  }

  return {
    ...teig,
    eaveHeight,
    newGeometri
  };
}

async function getElevation(coordinates, success, failure) {
  const newPunkter = JSON.stringify(coordinates).replace(/"/g, "");

  const query = `https:${config.WAAPIhoydeUrl}?Punkter=${newPunkter}&api_key=${config.apikey}`;

  const apiResult = await fetch(query, {
    headers: {
      Accept: "application/json",
    },
    method: "GET",
  });

  if (apiResult.status === 200) {
    return await getHeight(apiResult);
  }

  return null;
}

async function getHeight(apiResult) {
  const json = await apiResult.json();
  return json.PunktHoyder;
}

function getRoofEave(coordinates) {
  let height, index;
  for (let i = 0; i < coordinates.length; i++) {
    let newHeight = coordinates[i][2];
    if (!height || newHeight < height) {
      index = i;
      height = newHeight;
    }
  }
  return [
    {
      X: coordinates[index][0],
      Y: coordinates[index][1],
      Z: coordinates[index][2],
    },
  ];
}

function getNewGeometri(coordinates) {
  let newArray = [];
  
  coordinates.map((coordinate) => {
    coordinate.pop();
    [coordinate[0], coordinate[1]] = [coordinate[1], coordinate[0]];
    newArray.push(coordinate);
  });

  return newArray;
}
