import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import HouseImage from "../../assets/images/contactview.png";
import "./Unsubscribe.scss";

const Unsubscribe = () => {
  const { id } = useParams();

  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    const body = id.includes("@") ? { email: id } : { OrderId: id };

    axios
      .post(`${process.env.REACT_APP_SUNTALK_API_URL}/Unsubscribe`, body)
      .then((response) => {
        setIsLoaded(true);
        setError(null);
      })
      .catch((error) => {
        console.error(error);
        setIsLoaded(true);
        setError(error);
      });
  }, [id]);

  let content = null;

  if (!isLoaded) {
    content = <p>Lastet...</p>;
  }

  if (isLoaded && error) {
    content = <p>Oops! Noe gikk galt</p>;
  }

  if (isLoaded && !error) {
    content = (
      <>
        <h1 className="title">Du vil ikke lenger motta e-post fra oss</h1>
        <p>
		      Skulle du ombestemme deg er bærekraftige energiløsninger bare et klikk unna på Solkart.no
        </p>
      </>
    );
  }

  return (
    <div className="unsubscribe-page-section">
      <div className="unsubscribe-page-container">
        <div className="unsubscribe-page-description">
          <section>{content}</section>
          <div>
            <img
              className="unsubscribe-page-image"
              src={HouseImage}
              alt="A house with solar panels on its roof"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unsubscribe;
