import "./SubmissionView.scss";
import Search from "../../components/Search/Search";
import { motion } from "framer-motion";
import ContactForm from "../../components/ContactForm/ContactForm";
import contactViewImage from "../../assets/images/contactview.png";
import getMotionVariants from "../../api/getMotionVariants";

const SubmissionView = () => {
  return (
    // <motion.div
    //   variants={getMotionVariants()}
    //   initial="initial"
    //   animate="animate"
    //   transition={{ duration: 1, ease: "easeInOut" }}
    // >
      <div className="contact-view-page-section">
        <div className="contact-view-page-container">
          <Search />
          <div className="contact-view-page-description">
            <div className="contact-view-us-text">
              <h1>Takk for meldingen</h1>
              <p className="subtitle">
                En av våre representanter vil komme tilbake til deg så snart som
                mulig.
              </p>
              <p className="subtitle2">
                Solkart.no drives av selskapet Solcellespesialisten AS.
                <br /> Du kan kontakte oss på følgende måter:
              </p>
            </div>
            <div className="contact-view-info">
              <ContactForm />
              <div className="contact-view-image">
                <img
                  src={contactViewImage}
                  alt="solcellespesialisten-contact-us-view-solcelle.png"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    // </motion.div>
  );
};

export default SubmissionView;
