export const Envelopeicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="23"
      viewBox="0 0 30 23"
      fill="none"
    >
      <path
        d="M27.1875 0.5H2.8125C1.23047 0.5 0 1.78906 0 3.3125V20.1875C0 21.7695 1.23047 23 2.8125 23H27.1875C28.7109 23 30 21.7695 30 20.1875V3.3125C30 1.78906 28.7109 0.5 27.1875 0.5ZM2.8125 2.375H27.1875C27.6562 2.375 28.125 2.84375 28.125 3.3125V5.77344C26.8359 6.82812 24.9609 8.35156 19.2773 12.8633C18.2812 13.6836 16.3477 15.5586 15 15.5C13.5938 15.5586 11.6602 13.6836 10.6641 12.8633C4.98047 8.35156 3.10547 6.82812 1.875 5.77344V3.3125C1.875 2.84375 2.28516 2.375 2.8125 2.375ZM27.1875 21.125H2.8125C2.28516 21.125 1.875 20.7148 1.875 20.1875V8.17578C3.16406 9.28906 5.27344 10.9883 9.49219 14.3281C10.7227 15.3242 12.832 17.4336 15 17.375C17.1094 17.4336 19.2188 15.3242 20.4492 14.3281C24.668 10.9883 26.7773 9.28906 28.125 8.17578V20.1875C28.125 20.7148 27.6562 21.125 27.1875 21.125Z"
        fill="#5F6368"
      />
    </svg>
  );
};
