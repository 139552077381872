import { OrangeCloseicon } from "../icons/OrangeCloseicon";
import "./Cookie.scss";
import Cookies from "universal-cookie";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";

const Cookie = () => {
  const cookies = new Cookies();
  const [newCookie, setNewCookie] = useState("");
  const [show, setShow] = useState(false);
  const access_token_skip = cookies.get("access_token_skip");
  const access_token_reject = cookies.get("access_token_reject");

  useEffect(() => {
    setNewCookie(access_token_skip);
  }, [access_token_skip, access_token_reject]);

  const setCookies = (status) => {
    let uuid = uuidv4();
    if (status === "accept") {
      cookies.set("access_token_accept", uuid, {
        path: "/",
        expires: new Date(Date.now() + 8.64e7),
      });
      cookies.remove("access_token_skip");
    } else {
      cookies.set("access_token_reject", uuid, {
        path: "/",
        expires: new Date(Date.now() + 180000),
      });
    }
    setNewCookie(uuid);
  };

  return newCookie !== undefined && !show ? (
    <div className="cookie-section">
      <p>
        Ved å klikke "Godta", godtar du bruken av informasjonskapsler på enheten
        din i samsvar med våre retningslinjer for <span>personvern</span> og
        informasjonskapsler.
      </p>
      <div className="cookie-accept-reject">
        <button
          onClick={() => {
            setCookies("accept");
          }}
        >
          Aksepter
        </button>
        <button
          onClick={() => {
            setShow(true)
            setCookies("reject");
          }}
        >
          <OrangeCloseicon />
        </button>
      </div>
    </div>
  ) : null;
};

export default Cookie;
