import axios from "axios";
import { setShow } from "../Show/action";
import {
  SET_COMMENT,
  SET_CUSTOMER_INFO,
  SET_ORDER_INFO,
  SET_ORDER_PANEL_COUNT,
  SET_ORDER_ROOF_INFO,
  SET_ORDER_ROOF_TYPE,
  SET_PANEL_TYPE,
  SET_ROOF_INFORMATION,
  SET_SFNumber,
  SET_ORDER,
  SET_ENOVE,
  SET_PARTNER_ID,
} from "./types";

export const setOrderPanelCount = (panelCount) => {
  return {
    type: SET_ORDER_PANEL_COUNT,
    panelCount,
  };
};

export const setOrderRoofInfo = (roofOrderInfo) => {
  return {
    type: SET_ORDER_ROOF_INFO,
    roofOrderInfo,
  };
};

export const setCustomerInfo = (customerInfo) => {
  return {
    type: SET_CUSTOMER_INFO,
    customerInfo,
  };
};

export const setOrderRoofType = (orderRoofType) => {
  return {
    type: SET_ORDER_ROOF_TYPE,
    orderRoofType,
  };
};

export const setPanelType = (panelType) => {
  return {
    type: SET_PANEL_TYPE,
    panelType,
  };
};

export const setOrderInfo = (orderInfo) => {
  return {
    type: SET_ORDER_INFO,
    orderInfo,
  };
};

export const setRoofInformation = (roofInformation) => {
  return {
    type: SET_ROOF_INFORMATION,
    roofInformation,
  };
};

export const setOrder = (order) => {
  return {
    type: SET_ORDER,
    order,
  };
};

export const setSFNumber = (SFNumber) => {
  return {
    type: SET_SFNumber,
    SFNumber,
  };
};

export const setUserComment = (comment) => {
  return {
    type: SET_COMMENT,
    comment,
  };
};

export const setEnova = (enovaSupport) => {
  return {
    type: SET_ENOVE,
    enovaSupport,
  };
};

export const setPartnerId = (partnerId) => {
  return {
    type: SET_PARTNER_ID,
    partnerId,
  };
};

export const sendOrderData = (orderData) => {
  return (dispatch) => {
    let newObj = {
      id: 0,
      partnerId: Number(orderData.partnerId),
      customer: {
        firstName: orderData.customerInfo.name,
        lastName: orderData.customerInfo.surname,
        companyName: "",
        email: orderData.customerInfo.email,
        phone: orderData.customerInfo.phone,
      },
      plantId: "",
      panelTypeId: orderData.roofInformation.produkttype,
      panelCount: orderData.panelCount,
      roofTypeId: orderData.roofInformation.typetak,
      roofSlope: orderData.roofInformation.Helning.toFixed(1),
      eaveHeight: orderData.roofInformation.eaveHeight.toFixed(1),

      snowLoad: orderData.order.anleggsInformasjon.snolast,
      windLoad: orderData.order.anleggsInformasjon.vindlast,

      installationSize: "",
      options: orderData.comment,
      description: "",

      kWhYear: orderData.orderInfo.produksjon[12].kWh,
      budgetedTime:
        orderData.orderInfo.dagergjeldene * 8 +
        orderData.orderInfo.timergjeldene,

      location: {
        address: orderData.customerInfo.address,
        city: orderData.customerInfo.by,
        postalCode: orderData.customerInfo.postalCode,
        baseId: orderData.order.ruteplanlegger.baseId,

        travelDistance: orderData.order.ruteplanlegger.distansemeter,
        travelTime: orderData.order.ruteplanlegger.distansetid,
        longitude: orderData.roofInformation.lng,
        latitude: orderData.roofInformation.lat,
      },
      price: {
        materialPrice: orderData.orderInfo.materiell * 1.25,
        installationPrice: orderData.orderInfo.montasje * 1.25,
        electricianPrice: orderData.orderInfo.elektriker * 1.25,
        enovaSubsidy: orderData.enovaSupport,
      },
      baseId: orderData.order.ruteplanlegger.baseId,
      dagergjeldene: orderData.orderInfo.dagergjeldene,
      timergjeldene: orderData.orderInfo.timergjeldene,
      partnerPrice: orderData.orderInfo.partnerPris,
      voucherCode: orderData.orderInfo.voucherCode,
      discount: orderData.orderInfo.discount * 1.25,
      financingRequired:orderData.orderInfo.financingRequired
    };

    axios
      .post(`${process.env.REACT_APP_SUNFLOW_API_URL}/projects`, newObj)
      .then(() => {
        dispatch(setShow("step4"));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};
