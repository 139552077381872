import './ContactForm.scss'
import { Phoneicon } from "../../components/icons/Phoneicon";
import { Envelopeicon } from "../../components/icons/Envelopeicon";
import { Globeicon } from "../../components/icons/Globeicon";
import { Buildingicon } from "../../components/icons/Buildingicon";
import ButtonEmail from "../../components/SendEmail/ButtonEmail";
import { useLocation } from 'react-router';

const ContactForm = () => {
  
  const location = useLocation();

  return (
    <div className="contact-us-text-form">
    <div className="contact-us-text-form-row">
      <Phoneicon />
      <div>
        <p>Ring oss </p>
        <p>69 10 90 65</p>
      </div>
    </div>
    <div className="contact-us-text-form-row">
      <Envelopeicon />
      <div>
        <p>Chat med salg</p>
        <p>
          <ButtonEmail
            label=" salg.enebolig@solcellespesialisten.no"
            mailto="mailto:salg.enebolig@solcellespesialisten.no"
          />
        </p>
      </div>
    </div>
    <div className="contact-us-text-form-row" style={location.pathname === '/contact' ? {display : "none" } : {display : "flex" }}>
      <Buildingicon />
      <div>
        <p>Besøk oss</p>
        <p>Solcellespesialisten</p>
        <p> AS Stabburveien 1A </p>
        <p>1617 FREDRIKSTAD</p>
      </div>
    </div>
    <div className="contact-us-text-form-row">
      <a
        href="https://www.facebook.com/Solcellespesialisten"
        target="_blank"
      >
        <Globeicon />
      </a>

      <div className="facebook-info">
        <a
          href="https://www.facebook.com/Solcellespesialisten"
          target="_blank"
        >
          <p>Facebook</p>
          <p>Facebook.com/Solcellespesialisten </p>
        </a>
      </div>
    </div>
  </div>
  )
}

export default ContactForm