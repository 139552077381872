import "./RoofDescription.scss";
import { useSelector } from "react-redux";
import { Houseicon } from "../../components/icons/Houseicon";
import { Arrowicon } from "../../components/icons/Аrrowicon";
import { Lightbulbicon } from "../../components/icons/Lightbulbicon";
import { useState } from "react";
import { Buildingorangeicon } from "../icons/Buildingorangeicon";

const RoofDescription = () => {
  let address = useSelector((state) => state.Address.address);
  let checkedAddress = useSelector((state) => state.Address.checkedAddress);
  let [show, setShow] = useState(false);

  return Object.keys(address).length || Object.keys(checkedAddress).length ? (
    <div className="roof-description">
      <div className="roof-description-section">
        <div className="roof-select">
          <div className="roof-select-title">
            <h3>Velg Takflate</h3>
            <Buildingorangeicon />
          </div>
          <div className="roof-select-desc">
            <p>Her kan du velge takflaten du ønsker solcellene montert på</p>
          </div>
        </div>

        <div className="roof-address">
          <div className="roof-address-title">
            <h3>Adressen din</h3>
            <Houseicon />
          </div>
          <div className="roof-address-desc">
            {Object.keys(address).length > 0 ? (
              address.text.split(",").map((name, index) => {
                return <p key={index}>{name}</p>;
              })
            ) : (
              <>
                <p>
                  {checkedAddress.road} {checkedAddress.house_number}
                </p>
                <p>{checkedAddress.county}</p>
              </>
            )}
          </div>
        </div>
        <div className="roof-info">
          <div className="roof-info-title">
            <h3>Raske tips</h3>
            <button
              className={show ? "rotate-button" : ""}
              onClick={() => setShow((show = !show))}
            >
              <Arrowicon />
            </button>
          </div>
          <div className={`${show ? "expanded" : "collapsed"} roof-info-desc`}>
            <div>
              <p>
                <Lightbulbicon />
              </p>
              <p>
                "Hvor mørkere rødfarge hvor mer egnet er taket ditt for
                solceller. Generelt egner alle tak mot øst, vest eller sør seg
                for solceller."
              </p>
            </div>
            <div>
              <p>
                <Lightbulbicon />
              </p>
              <p>
                "På flate tak legger vi solcellene øst/vest for å maksimere
                plass og produksjon."
              </p>
            </div>
            <div>
              <p>
                <Lightbulbicon />
              </p>
              <p>
                "Den vanligste taktypen på skråtak i Norge er dobbeltkrum
                takstein".
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default RoofDescription;
