import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import "./Header.scss";
import logoImage from "../../assets/images/solkartlogo.svg";
import Sidebar from "react-sidebar";
import { useState } from "react";

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const [show, setShow] = useState(false);

  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
    if (sidebarOpen) {
      document
        .querySelector(".sidebar-root")
        .classList.remove("sidbar-container");
      document.querySelector("body").classList.remove("fixed");
    } else {
      document.querySelector(".sidebar-root").classList.add("sidbar-container");
      document.querySelector("body").classList.add("fixed");
    }
  };

  const changeShow = () => {
    setShow(false);
  };

  return (
    <>
      <div className="header">
        <div className="container">
          <nav className="navigation">
            <div className="logo-image">
              <Link to="/">
                <img src={logoImage} alt="" />
              </Link>
            </div>
            <button
              className="hamburger"
              onClick={() => onSetSidebarOpen(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="white"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <Sidebar
              sidebar={
                <ul className="sidebar-nav">
                  <li>
                    <Link to="/" onClick={() => onSetSidebarOpen(false)}>
                      Hjem
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" onClick={() => onSetSidebarOpen(false)}>
                      Om Solkart.no
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/solutions"
                      onClick={() => onSetSidebarOpen(false)}
                    >
                      Løsninger
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={() => onSetSidebarOpen(false)}>
                      Kontakt oss
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.solcellespesialisten.no/ofte-stilte-sp%C3%B8rsm%C3%A5l"
                      onClick={() => onSetSidebarOpen(false)}
                    >
                      FAQ
                    </Link>
                  </li>
                </ul>
              }
              open={sidebarOpen}
              onSetOpen={onSetSidebarOpen}
              styles={{ sidebar: { background: "white" } }}
              rootClassName={"sidebar-root"}
              sidebarClassName={"sidebar"}
              contentClassName={"sidebar-content"}
              overlayClassName={"sidbar-overlay"}
            >
              <button className="sidebar-action"></button>
            </Sidebar>
            <div className="navigation-menu">
              <ul>
                <li onClick={changeShow}>
                  <Link
                    className={
                      splitLocation[1] === "" && !show
                        ? "navigation-menu-item active-button"
                        : "navigation-menu-item"
                    }
                    to="/"
                  >
                    Hjem
                  </Link>
                </li>
                <li onClick={changeShow}>
                  <Link
                    className={
                      splitLocation[1] === "about" && !show
                        ? "navigation-menu-item-new active-button"
                        : "navigation-menu-item"
                    }
                    to="/about"
                  >
                    Om Solkart.no
                  </Link>
                </li>
                <li onClick={changeShow}>
                  <Link
                    className={
                      splitLocation[1] === "solutions" && !show
                        ? "navigation-menu-item-new active-button"
                        : "navigation-menu-item"
                    }
                    to="/solutions"
                  >
                    Løsninger
                  </Link>
                </li>
                <li onClick={changeShow}>
                  <Link
                    className={
                      splitLocation[1] === "contact" && !show
                        ? "navigation-menu-item-new active-button"
                        : "navigation-menu-item"
                    }
                    to="/contact"
                  >
                    Kontakt oss
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  {/* <Link
                      className={
                        splitLocation[1] === ""
                          ? "navigation-menu-item-new active-button"
                          : "navigation-menu-item"
                      }
                      to="https://www.solcellespesialisten.no/ofte-stilte-sp%C3%B8rsm%C3%A5l"
                    >
                      FAQ
                    </Link> */}
                  <a
                    className={
                      show === true
                        ? "navigation-menu-item-new active-button"
                        : "navigation-menu-item"
                    }
                    href="https://www.solcellespesialisten.no/ofte-stilte-sp%C3%B8rsm%C3%A5l"
                    target="_black"
                  >
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <Outlet />
      </div>
    </>
  );
};

export default Header;
