import React from "react";
import "./ErrorMessage.scss";

const ErrorMessage = () => {
  return (
    <div className="error-message">
      Vi fant ingen treff for søkeresultatene dine.
    </div>
  );
};

export default ErrorMessage;
