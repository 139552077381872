export const Leftarrowicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
    >
      <path
        d="M0.78125 6.1875C0.585938 6.38281 0.585938 6.65625 0.78125 6.85156L6.52344 12.6328C6.67969 12.8281 6.99219 12.8281 7.1875 12.6328L7.92969 11.8516C8.125 11.6562 8.125 11.3828 7.92969 11.1875L3.32031 6.5L7.92969 1.85156C8.125 1.65625 8.125 1.34375 7.92969 1.1875L7.1875 0.40625C6.99219 0.210938 6.67969 0.210938 6.52344 0.40625L0.78125 6.1875ZM7.03125 6.85156L12.7734 12.6328C12.9297 12.8281 13.2422 12.8281 13.4375 12.6328L14.1797 11.8516C14.375 11.6562 14.375 11.3828 14.1797 11.1875L9.57031 6.5L14.1797 1.85156C14.375 1.65625 14.375 1.34375 14.1797 1.1875L13.4375 0.40625C13.2422 0.210938 12.9297 0.210938 12.7734 0.40625L7.03125 6.1875C6.83594 6.38281 6.83594 6.65625 7.03125 6.85156Z"
        fill="white"
      />
    </svg>
  );
};
