export const Buildinggreyicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
    >
      <path
        d="M7.59961 4.01172C7.59961 3.80078 7.38867 3.58984 7.17773 3.58984H5.77148C5.52539 3.58984 5.34961 3.80078 5.34961 4.01172V5.41797C5.34961 5.66406 5.52539 5.83984 5.77148 5.83984H7.17773C7.38867 5.83984 7.59961 5.66406 7.59961 5.41797V4.01172ZM11.6777 3.58984H10.2715C10.0254 3.58984 9.84961 3.80078 9.84961 4.01172V5.41797C9.84961 5.66406 10.0254 5.83984 10.2715 5.83984H11.6777C11.8887 5.83984 12.0996 5.66406 12.0996 5.41797V4.01172C12.0996 3.80078 11.8887 3.58984 11.6777 3.58984ZM7.17773 6.96484H5.77148C5.52539 6.96484 5.34961 7.17578 5.34961 7.38672V8.79297C5.34961 9.03906 5.52539 9.21484 5.77148 9.21484H7.17773C7.38867 9.21484 7.59961 9.03906 7.59961 8.79297V7.38672C7.59961 7.17578 7.38867 6.96484 7.17773 6.96484ZM11.6777 6.96484H10.2715C10.0254 6.96484 9.84961 7.17578 9.84961 7.38672V8.79297C9.84961 9.03906 10.0254 9.21484 10.2715 9.21484H11.6777C11.8887 9.21484 12.0996 9.03906 12.0996 8.79297V7.38672C12.0996 7.17578 11.8887 6.96484 11.6777 6.96484ZM7.17773 10.3398H5.77148C5.52539 10.3398 5.34961 10.5508 5.34961 10.7617V12.168C5.34961 12.4141 5.52539 12.5898 5.77148 12.5898H7.17773C7.38867 12.5898 7.59961 12.4141 7.59961 12.168V10.7617C7.59961 10.5508 7.38867 10.3398 7.17773 10.3398ZM11.6777 10.3398H10.2715C10.0254 10.3398 9.84961 10.5508 9.84961 10.7617V12.168C9.84961 12.4141 10.0254 12.5898 10.2715 12.5898H11.6777C11.8887 12.5898 12.0996 12.4141 12.0996 12.168V10.7617C12.0996 10.5508 11.8887 10.3398 11.6777 10.3398ZM16.5996 17.5469C16.5996 17.3359 16.3887 17.125 16.1777 17.125H15.4746V1.09375C15.4746 0.636719 15.0879 0.25 14.6309 0.25H2.81836C2.32617 0.25 1.97461 0.636719 1.97461 1.09375V17.125H1.27148C1.02539 17.125 0.849609 17.3359 0.849609 17.5469V18.25H16.5996V17.5469ZM14.3496 17.125H9.84961V14.1367C9.84961 13.9258 9.63867 13.7148 9.42773 13.7148H8.02148C7.77539 13.7148 7.59961 13.9258 7.59961 14.1367V17.125H3.09961V1.375H14.3496V17.125Z"
        fill="#C9C5C5"
      />
    </svg>
  );
};
