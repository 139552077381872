export function getRoofCalculation(finalRoof) {
  const baseSupport = 7500;
  let kwpCount = finalRoof?.kwp / 1000;
  let kwpSupport = kwpCount > 20 ? 40000 : kwpCount * 1250; //per kwp enova support
  let enova = baseSupport + kwpSupport;
  if (enova > 32500) { //original calculations: finalRoof.pris * 1.25 * 0.35, capping enova support at 35% of sale price
    enova = 32500;
  }
  let netP = finalRoof?.pris  * 1.25 - enova;
  let lcoeC = netP / (finalRoof?.kwtAar * 30 * 0.925);

  return {
    lcoeC,
    enova,
    netP, 
  };
}
