import "./Search.scss";
import { useState } from "react";
import { Searchicon } from "../../components/icons/Searchicon";
import { useDispatch } from "react-redux";
import { setAddress } from "../../store/Address/action";
import { config } from "../../api/config";
import { useLocation, useNavigate } from "react-router";
import { setRoofInfo } from "../../store/Roof/action";
import { setOrderPanelCount } from "../../store/Order/action";

const Search = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [placeholderText, setPlaceholderText] = useState(
    "Søk på stedsnavn eller adresse"
  );
  const [shows, setShows] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [finalResult, setFinalResult] = useState({});
  const [finalText, setFinalText] = useState("");

  const dispatch = useDispatch();

  async function searchAddress(searchText) {
    if (searchText !== null && searchText !== "") {
      const query = `https:${config.WAAPIsearchUrl}/suggest/kommunecustom?Query=${searchText}&Size=5&Targets=gateadresse`;

      const apiResult = await fetch(query, {
        headers: {
          Accept: "application/json",
          "X-WAAPI-TOKEN": `${config.apikey}`,
        },
        method: "GET",
      });

      if (apiResult.status === 200) {
        await searchResultToAdress(apiResult);
      }
    } else {
      setSearchResult([]);
      setShows(false);
    }
  }

  async function searchResultToAdress(apiResult) {
    const suggestions = [];
    const json = await apiResult.json();

    if (json.Options.length > 0) {
      json.Options.forEach((suggestion) => {
        setShows(true);
        if (
          suggestion?.PayLoad?.Posisjon?.X &&
          suggestion?.PayLoad?.Posisjon?.Y &&
          suggestion?.PayLoad?.AdresseMatrikkelNummer
        ) {
          suggestions.push({
            id: suggestion.Id,
            matrikkelId: suggestion.PayLoad.AdresseMatrikkelNummer,
            text: suggestion.Text,
            latlng: {
              lat: suggestion.PayLoad.Posisjon.Y,
              lng: suggestion.PayLoad.Posisjon.X,
            },
          });
        } else if (
          suggestion?.PayLoad?.Posisjon?.X &&
          suggestion?.PayLoad?.Posisjon?.Y &&
          suggestion?.PayLoad?.MatrikkelNummer
        ) {
          suggestions.push({
            id: suggestion.Id,
            matrikkelId: suggestion.PayLoad.MatrikkelNummer,
            text: suggestion.Text,
            latlng: {
              lat: suggestion.PayLoad.Posisjon.Y,
              lng: suggestion.PayLoad.Posisjon.X,
            },
          });
        }

        setSearchResult(suggestions);
      });
    } else {
      setSearchResult([]);
      setShows(false);
    }
  }

  const sendData = () => {
    window.sessionStorage.setItem("roofID", 0);
    window.sessionStorage.setItem("roofName", null);
    if (location.pathname !== "/search") {
      navigate("/search");
    }
    dispatch(setOrderPanelCount(""));
    dispatch(setRoofInfo(""));
    dispatch(setAddress(finalResult));
  };

  const searchData = (text) => {
    window.sessionStorage.setItem("roofID", 0);
    window.sessionStorage.setItem("roofName", null);
    if (location.pathname !== "/search") {
      navigate("/search");
    }
    setFinalResult(text);
    setShows(false);
    setFinalText(text.text);
    dispatch(setOrderPanelCount(""));
    dispatch(setRoofInfo(""));
    dispatch(setAddress(text));
  };

  return (
    <div className="search-section">
      <div className="search-container">
        <div className="search-input">
          <Searchicon />
          <input
            type="text"
            placeholder={placeholderText}
            onBlur={() => setPlaceholderText("Søk på stedsnavn eller adresse")}
            onClick={() => setPlaceholderText("Søker...")}
            onChange={(e) => {
              searchAddress(e.target.value);
              setFinalText(e.target.value);
            }}
            value={finalText}
          />
          <div
            className="show-results"
            style={shows ? { display: "block" } : { display: "none" }}
          >
            <ul>
              {searchResult.map((text) => {
                return (
                  <li
                    key={text.id}
                    onClick={() => {
                      searchData(text);
                    }}
                  >
                    {text.text}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <button type="submit" className="search-button" onClick={sendData}>
          Søk
        </button>
      </div>
    </div>
  );
};

export default Search;
