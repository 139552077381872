import Search from "../../components/Search/Search";
import "./Contact.scss";
import { motion } from "framer-motion";
import ContactForm from "../../components/ContactForm/ContactForm";
import getMotionVariants from "../../api/getMotionVariants";

const Contact = () => {
  return (
    // <motion.div
    //   variants={getMotionVariants()}
    //   initial="initial"
    //   animate="animate"
    //   transition={{ duration: 1, ease: "easeInOut" }}
    // >
      <div className="contact-page-section">
        <div className="contact-page-container">
          <Search />
          <div className="contact-page-description">
            <div className="contact-us-text">
              <h1>Kontakt oss</h1>
              <p className="subtitle">
                Solkart.no drives av selskapet Solcellespesialisten AS. Du kan
                kontakte oss på følgende måter:
              </p>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    // </motion.div>
  );
};

export default Contact;
