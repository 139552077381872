export const Closeicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        d="M8.19385 6.5L12.4126 2.32031C12.647 2.08594 12.647 1.65625 12.4126 1.42188L11.436 0.445312C11.2017 0.210938 10.772 0.210938 10.5376 0.445312L6.35791 4.66406L2.13916 0.445312C1.90479 0.210938 1.4751 0.210938 1.24072 0.445312L0.26416 1.42188C0.0297852 1.65625 0.0297852 2.08594 0.26416 2.32031L4.48291 6.5L0.26416 10.7188C0.0297852 10.9531 0.0297852 11.3828 0.26416 11.6172L1.24072 12.5938C1.4751 12.8281 1.90479 12.8281 2.13916 12.5938L6.35791 8.375L10.5376 12.5938C10.772 12.8281 11.2017 12.8281 11.436 12.5938L12.4126 11.6172C12.647 11.3828 12.647 10.9531 12.4126 10.7188L8.19385 6.5Z"
        fill="white"
      />
    </svg>
  );
};
