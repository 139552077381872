import Search from "../../components/Search/Search";
import "./About.scss";
import slide1 from "../../assets/images/slider-1-new.png";
import slide2 from "../../assets/images/slider-2-new.png";
import slide3 from "../../assets/images/slider-3-new.png";
import slide4 from "../../assets/images/slider-4-new.png";
import slide5 from "../../assets/images/slider-5-new.png";
import flag from "../../assets/images/flag.png";
import propertie from "../../assets/images/properties.png";
import cloud from "../../assets/images/cloud.png";
import panel from "../../assets/images/panel.png";
import system from "../../assets/images/system.png";
import { Arrowicon } from "../../components/icons/Аrrowicon";
import { useState } from "react";
import { motion } from "framer-motion";
import { useEffect } from "react";
import CrossfadeImage from "react-crossfade-image";
import ButtonEmail from "../../components/SendEmail/ButtonEmail";
import getMotionVariants from "../../api/getMotionVariants";

const About = () => {
  const [texts, setTexts] = useState([
    {
      id: 1,
      text1:
        "Norkart AS har analysert alle eiendommer i Norge og tilgjengeliggjort de datene som behøves for å finne ut hva solpotensialet til et tak er. Dette er primært takvinkel, fysiske mål på taket samt himmelretning. Alle detaljerte produksjonsdata er hentet fra PVGIS, som er EU sitt solkartleggingsprogram. Solcellespesialisten har videre hentet inn snø og vindlast kravene til alle kommunene i Norge. Alle disse dataene er videre behandlet av en algoritme som er utviklet av Solcellespesialisten på bakgrunn av de faktiske målingene vi har gjort hos våre solcelle kunder de siste 10 årene. Alle tallene er å anse som estimater og kan være feil. VIKTIG: Tallmaterialet bør ikke være ditt eneste grunnlag for å gjøre en solcelleinvestering. Det er også viktig å få med seg at lokale skyggeforhold ikke er hensyntatt i denne versjonen.",
      showText: false,
    },

    {
      id: 2,
      text2:
        "Solkart.no benytter PVGIS sin ERA5-database for å estimere solinnstråling og energiproduksjon, og arver dermed den høye treffsikkerheten til denne databasen. Våre målinger viser at ERA5 leverer mer stabile resultater for større deler av landet sammenlignet med SARAH-databasen. I tillegg har vi justert dataene fra PVGIS basert på faktiske produksjonsdata fra våre kunder, noe som vil bidra til å øke treffsikkerheten ytterligere over tid.",
      showText: false,
    },

    {
      id: 3,
      text3:
        "Alle prisene på Solkart.no er estimater generert av Solcellespesialisten. I tillegg til alle fysiske komponenter og montasjetid, hensynstas dagens valutakurser, reisedistanse og tid fra nærmeste base, evt. kost/losji der der er langt til nærmeste base og lokale snø og vindlastkrav.",
      showText: false,
    },
  ]);

  const [curImg, setCurImg] = useState(0);

  const slideImages = [
    {
      url: slide4,
      caption: "solcellespesialisten-about-solcelle.png",
    },
    {
      url: slide1,
      caption: "solcellespesialisten-about-metalltak-metal-roof-solcelle.png",
    },
    {
      url: slide2,
      caption: "solcellespesialisten-about-tegltak-tile-roof-solcelle.png",
    },
    {
      url: slide3,
      caption: "solcellespesialisten-about-metalltak-metal-roof-solcelle.png",
    },
    {
      url: slide5,
      caption: "solcellespesialisten-about-metalltak-metal-roof-solcelle.png",
    },
  ];

  useEffect(() => {
    let interval = null;

    interval = setInterval(() => {
      if (curImg < 4) {
        setCurImg((img) => img + 1);
      } else {
        setCurImg(0);
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [curImg]);

  const readMore = (id) => {
    const cloneTexts = [...texts];
    let txt = cloneTexts.find((el) => el.id === id);
    txt.showText = !txt.showText;
    setTexts(cloneTexts);
  };

  return (
    // <motion.div
    //   variants={getMotionVariants()}
    //   initial="initial"
    //   animate="animate"
    //   transition={{ duration: 1, ease: "easeInOut" }}
    // >
      <div className="about-page-section">
        <div className="about-page-container">
          <Search />
          <div className="about-page-description">
            <h1 className="title">Om Solkart.no</h1>
            <div className="subtitle">
              <p className="subtitle1">
                Solkart.no er et hjelpemiddel til deg som
              </p>
              <p className="subtitle2">
                vurderer å legge solceller på ditt hus.
              </p>
            </div>
            <div className="about-home-pic">
              <CrossfadeImage
                src={slideImages[curImg].url}
                alt={slideImages[curImg].caption}
                duration={3000}
                timingFunction={"ease-out"}
              />
            </div>
            <div className="about-flag">
              <p>
                På Solkart.no så får du se hvilket av dine takflater som egner
                seg best til solceller.
              </p>
              <img alt="Home" src={flag} />
            </div>
            <div className="about-main">
              <p>
                Ved å klikke på taket du ønsker å installere solceller på, får
                du opp alt du trenger å vite om dine muligheter. Du får se hvor
                stort anlegg det er plass til, hvor mye du kan forvente å få
                produsert pr år, eller ferdig montert.
              </p>
              <p>
                Solkart.no er laget i et samarbeide med Solcellespesialisten og
                Norkart AS. Hensikten med Solkart.no er fremme utbredelsen av
                solceller i Norge, samt å synliggjøre den store ressursen
                energien fra sola også utgjør i Norge. Målet med solkart.no er
                at tjenesten skal være et enkelt og godt verktøy for både små og
                store eiendomseiere i Norge.
              </p>
            </div>
            <div className="about-desc-parts">
              <div className="about-image">
                <img
                  alt="solcellespesialisten-about-properties-roof-solcelle.png"
                  src={propertie}
                />
              </div>
              <div className="about-text">
                <h1>Hvordan er Solkart.no laget?</h1>
                <p
                  className={`${
                    texts[0].showText ? "expanded" : "collapsed"
                  } text-transition`}
                >
                  {texts[0].showText
                    ? texts[0].text1
                    : `${texts[0].text1.substring(
                        0,
                        texts[0].text1.length / 2
                      )}`}
                </p>
                <p>
                  {texts[0].showText ? "Lese mindre" : "Les mer"}
                  <button
                    className={texts[0].showText ? "rotate-button" : ""}
                    onClick={() => readMore(texts[0].id)}
                  >
                    <Arrowicon />
                  </button>
                </p>
              </div>
            </div>
            <div className="about-desc-parts2">
              <div className="about-text">
                <h1>Hvor eksakt er Solkart.no?</h1>
                <p
                  className={`${
                    texts[1].showText ? "expanded1" : "collapsed1"
                  } text-transition`}
                >
                  {texts[1].showText
                    ? texts[1].text2
                    : `${texts[1].text2.substring(
                        0,
                        texts[1].text2.length / 2
                      )}`}
                </p>
                <p>
                  {texts[1].showText ? "Lese mindre" : "Les mer"}
                  <button
                    className={texts[1].showText ? "rotate-button" : ""}
                    onClick={() => readMore(texts[1].id)}
                  >
                    <Arrowicon />
                  </button>
                </p>
              </div>
              <div className="about-image">
                <img
                  alt="solcellespesialisten-about-weather-solcelle.png"
                  src={cloud}
                />
              </div>
            </div>
            <div className="about-desc-parts">
              <div className="about-image">
                <img
                  alt="solcellespesialisten-about-prices-solcelle.png"
                  src={panel}
                />
              </div>
              <div className="about-text">
                <h1>Hva er hensynsstatt i prisene som vises på Solkart.no?</h1>
                <p
                  className={`${
                    texts[2].showText ? "expanded2" : "collapsed2"
                  } text-transition`}
                >
                  {texts[2].showText
                    ? texts[2].text3
                    : `${texts[2].text3.substring(
                        0,
                        texts[2].text3.length / 2
                      )}`}
                </p>
                <p>
                  {texts[2].showText ? "Lese mindre" : "Les mer"}
                  <button
                    className={texts[2].showText ? "rotate-button" : ""}
                    onClick={() => readMore(texts[2].id)}
                  >
                    <Arrowicon />
                  </button>
                </p>
              </div>
            </div>
            <div className="about-desc-parts2">
              <div className="about-text last-card">
                <h1>Ønsker du å kjøpe et solcelleanlegg?</h1>
                <p style={{ display: "unset" }}>
                  Bestill rett på solkart.no, eller ta kontakt med
                  Solcellespesialisten på &nbsp;<span>69 10 90 65</span>
                </p>
                <p style={{ display: "unset" }}>
                  eller &nbsp;
                  <ButtonEmail
                    label="salg.enebolig@solcellespesialisten.no"
                    mailto="mailto:salg.enebolig@solcellespesialisten.no"
                  />
                </p>
              </div>
              <div className="about-image">
                <img
                  alt="solcellespesialisten-about-information-solcelle.png"
                  src={system}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    // </motion.div>
  );
};

export default About;
