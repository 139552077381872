import OrderState from "./state";
import {
  SET_COMMENT,
  SET_CUSTOMER_INFO,
  SET_ENOVE,
  SET_ORDER,
  SET_ORDER_INFO,
  SET_ORDER_PANEL_COUNT,
  SET_ORDER_ROOF_INFO,
  SET_ORDER_ROOF_TYPE,
  SET_PANEL_TYPE,
  SET_PARTNER_ID,
  SET_ROOF_INFORMATION,
  SET_SFNumber,
} from "./types";

const Reducer = (state = OrderState, options) => {
  switch (options.type) {
    case SET_ORDER_PANEL_COUNT:
      state.panelCount = options.panelCount;
      break;
    case SET_ORDER_ROOF_INFO:
      state.roofOrderInfo = options.roofOrderInfo;
      break;
    case SET_CUSTOMER_INFO:
      state.customerInfo = options.customerInfo;
      break;
    case SET_ORDER_ROOF_TYPE:
      state.orderRoofType = options.orderRoofType;
      break;
    case SET_PANEL_TYPE:
      state.panelType = options.panelType;
      break;
    case SET_ORDER_INFO:
      state.orderInfo = options.orderInfo;
      break;
    case SET_ROOF_INFORMATION:
      state.roofInformation = options.roofInformation;
      break;
    case SET_ORDER:
      state.order = options.order;
      break;
    case SET_SFNumber:
      state.SFNumber = String(options.SFNumber);
      break;
    case SET_COMMENT:
      state.comment = options.comment;
      break;
    case SET_ENOVE:
      state.enovaSupport = options.enovaSupport;
      break;
    case SET_PARTNER_ID:
      state.partnerId = options.partnerId;
      break;
    default:
      break;
  }

  return { ...state };
};

export default Reducer;
