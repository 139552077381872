import { SET_SHOW, SET_SHOW_COOKIE } from "./types";

export function setShow(show) {
  return {
    type: SET_SHOW,
    show,
  };
}

export function setShowCookie(showCookie) {
  return {
    type: SET_SHOW_COOKIE,
    showCookie,
  };
}
