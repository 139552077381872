export const OrangeCloseicon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="13"
        viewBox="0 0 14 13"
        fill="none"
      >
        <path
          d="M8.53906 6.5L12.5625 2.51562L13.3828 1.69531C13.5 1.57812 13.5 1.38281 13.3828 1.22656L12.5234 0.367188C12.3672 0.25 12.1719 0.25 12.0547 0.367188L7.25 5.21094L2.40625 0.367188C2.28906 0.25 2.09375 0.25 1.9375 0.367188L1.07812 1.22656C0.960938 1.38281 0.960938 1.57812 1.07812 1.69531L5.92188 6.5L1.07812 11.3438C0.960938 11.4609 0.960938 11.6562 1.07812 11.8125L1.9375 12.6719C2.09375 12.7891 2.28906 12.7891 2.40625 12.6719L7.25 7.82812L11.2344 11.8516L12.0547 12.6719C12.1719 12.7891 12.3672 12.7891 12.5234 12.6719L13.3828 11.8125C13.5 11.6562 13.5 11.4609 13.3828 11.3438L8.53906 6.5Z"
          fill="#F67736"
        />
      </svg>
    );
  };
  