import Search from "../../components/Search/Search";
import "./Solutions.scss";
import rec1 from "../../assets/images/Takstein.png";
import rec2 from "../../assets/images/Takpapp.png";
import rec3 from "../../assets/images/Takshingel.png";
import rec4 from "../../assets/images/Decra.png";
import rec5 from "../../assets/images/Folietak.png";
import rec6 from "../../assets/images/Takpanner.png";
import premiumPanel from "../../assets/images/premiumpanel.png";
import performancePanel from "../../assets/images/performancepanel.png";
import economyPanel from "../../assets/images/economypanel.png";
import { motion } from "framer-motion";
import getMotionVariants from "../../api/getMotionVariants";

const Solutions = () => {
  const images = [
    {
      id: 1,
      img: rec1,
      label: "Takstein",
      caption: "solcellespesialisten-takstein-solcelle.png",
    },
    {
      id: 2,
      img: rec2,
      label: "Takpapp",
      caption: "solcellespesialisten-takpapp-solcelle.png",
    },
    {
      id: 3,
      img: rec3,
      label: "Takshingel",
      caption: "solcellespesialisten-takshingel-solcelle.png",
    },
    {
      id: 4,
      img: rec4,
      label: "Decra",
      caption: "solcellespesialisten-decra-solcelle.png",
    },
    {
      id: 5,
      img: rec5,
      label: "Folietak",
      caption: "solcellespesialisten-Folietak-solcelle.png",
    },
    {
      id: 6,
      img: rec6,
      label: "Takpanner/trapes/bølgeblikk",
      caption: "solcellespesialisten-takpanner-solcelle.png",
    },
  ];

  return (
    // <motion.div
    //   variants={getMotionVariants()}
    //   initial="initial"
    //   animate="animate"
    //   transition={{ duration: 1, ease: "easeInOut" }}
    // >
    <div className="solutions-page-section">
      <div className="solutions-page-container">
        <Search />
        <div className="solutions-page-description">
          <h1 className="title">Løsninger</h1>
          <div className="subtitle">
            <p>
              Her på solkart.no så presenterer vi en rekke solcelleløsninger fra
              noen av verdens beste leverandører. Under ser du litt generell
              informasjon om de ulike løsningene vi tilbyr.
            </p>
          </div>
          <div className="assembly-solutions">
            <h1>Monteringsløsninger</h1>
            <p>
              Solcellespesialisten har i flere år testet en rekke løsninger som
              vi på papiret mente burde passet det norske markedet og de norske
              forholdene. Resultatet av dette et stort spekter av festeløsninger
              som gjør at vi kan montere solceller på de aller fleste tak som
              finnes i Norge. Da solceller ofte har aluminiumsrammer, og selve
              rammesystemet er av aluminium er det viktig at alle festekroker og
              skruer som benyttes ikke “spiser opp” aluminiumen. Alle våre
              løsninger er laget av metall som tåler norske snø-laster og
              samtidig ikke “spiser opp” aluminiumen.
            </p>
          </div>
          <div className="external-mounting">
            <h1>Utenpåliggende montasje</h1>
            <p>
              Som kjernen i våre montasje systemer benyttes IBC Topfix 200 for
              skråtak, eller IBC Aerofix eller Van Der Valk Pro for flate tak.
              Vi har løsninger som tåler snølaster opp til 5kN/m2, og lagerfører
              takfester for:
            </p>
          </div>
          <div className="roof-pics">
            {images.map((image, index) => {
              return (
                <div key={index}>
                  <img src={image.img} alt={image.caption} />
                  <label>{image.label}</label>
                </div>
              );
            })}
          </div>
          <div className="integrated-solution">
            <h1>Integrert løsning</h1>
            <p>
              En integrert løsning, betyr at man erstatter et annet
              bygningselement med solceller, mest vanlig er takstein.
              Solcellespesialisten er norsk distributør for den Franske
              produsentene IRFTS og GSE. Disse leverer en meget god og
              kostnadseffektiv løsning for å integrere vanlige solceller inn i
              takflaten. Løsningen erstatter da f. eks takstein, og bør vurderes
              ved rehabilitering av tak og nybygg. Selve løsningen tilbyr bedre
              vær og vindbeskyttelse enn vanlig takstein.
            </p>
          </div>
          <div className="solar-panels">
            <h1>Solcellepaneler</h1>
            <p>
              Solcellepanelene er sammen med vekselretteren de delene som gjør
              om solenergien til strøm du kan bruke i bygget ditt. Hvis vi
              generaliserer litt, så kan vi si at hvis du sammenligner ulike
              solcellepaneler med samme effekt, feks. 3700W, så vil alle disse
              produsere det samme i løpet av et år. De vanligste solcellene vi
              installerer i Norge i dag er monokrystallinske solceller, som er
              svakt blåaktige i fargen og klarer å gjøre om ca 22% av sollyset
              til strøm.
            </p>
          </div>
          <div className="solutions-cards">
            <div className="solution-card">
              <h1>Premium</h1>
              <p>410Wp helsorte solcellepaneler med half cut technology.</p>
              <div>
                <img src={premiumPanel} alt="Premium Panel" />
              </div>
            </div>
            <div className="solution-card">
              <h1>Performance</h1>
              <p>
                430Wp helsorte solcellepaneler med half cut eller shingled
                technology.
              </p>
              <div>
                <img src={performancePanel} alt="Performance Panel" />
              </div>
            </div>
            <div className="solution-card">
              <h1>Economy</h1>
              <p>505Wp, Sort ramme med hvit bakplate.</p>
              <div className="economy-image">
                <img src={economyPanel} alt="Economy Panel" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </motion.div>
  );
};

export default Solutions;
